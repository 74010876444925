@import "../../../node_modules/bulma/sass/utilities/initial-variables.sass";

.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;
  z-index: 10;

  transition: 500ms;
  background-color: rgb(0, 0, 0);

  &.visible {
    opacity: 0.25;
    visibility: visible;
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  > svg {
    width: 50px;
    height: 50px;

    @media screen and (min-width: $tablet) {
      width: 5vw;
      height: 5vw;
    }

    color: rgb(255, 255, 255);
    animation: rotating 1s linear infinite;
  }

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
}