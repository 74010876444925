@import "../../styles/shared.scss";

.Sidebar {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  max-width: 25%;
  height: 100vh;
  overflow: hidden;
  box-shadow: 0px -4px 8px 10px rgba(40, 40, 40, 0.1);

  .Sidebar-header {
    width: 100%;
    padding: 16px;
    display: flex;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 2;
  }

  .Sidebar-content {
    flex: 1 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
  }

  .Sidebar-footer {
    text-align: center;
    box-shadow: -2px 0px 4px 5px rgba(40, 40, 40, 0.1);
    z-index: 2;
  }
}
