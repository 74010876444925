.Form {
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 200ms ease-in-out;
  transform: translate(-50%, -50%);
  padding: 16px;
  border-radius: 8px;
  min-width: 300px;
  max-width: 400px;
  box-sizing: content-box;
  z-index: 1;

  &.Form__on-top {
    top: 18px;
    transform: translate(-50%);
  }

  .Form-content {
    display: flex;
    flex-direction: column;
    text-align: start;
  }
}
