.Map {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  background-color: #75cff0;

  .Map-content {
    width: 100%;
    height: 100%;
    position: relative;
  }
}
